import React from 'react';

import Layout from '../components/Layout';

const herobg = '#1c2841', herofcolor='#efefef';
const TransformPage = () => (
  <Layout>
    <div id="main row" className='col-12' >
        <div className='row' style={{backgroundColor:herobg, color:herofcolor, padding:'2em 0'}}>
            <header className='col-9 inner' style={{margin:'.25em auto'}}>
                        <h1 style={{letterSpacing:'.1em'}}>Cloutics.com/Cloutics.net - Terms of use</h1>
                </header>
        </div>
        <div className="inner" style={{margin:'.25em auto'}}>
            <p style={{ fontSize:'70%', margin:'.5em auto 1em', textAlign:'justify'}}>
                These "Terms of Use" set forth sets the terms and conditions that apply to your use of  (the "Website"). 
                By using the Website (other than to read this page for the first time), you agree to comply with all of 
                the Terms of Use set forth herein. The right to use the Website is personal to you and is not transferable 
                to any other person or entity.
            </p>
            <p style={{fontSize:'90%', fontWeight:400, margin:'.5em 0 '}}>
                Copyrights and Trademarks
            </p>
            <p style={{ fontSize:'70%', margin:'.5em auto 1em', textAlign:'justify'}}>

                <li>All materials contained on the Website are Copyright of Cloutics Inc or of the respective owners. 
                    All rights reserved.</li>
                <li>No person is authorized to use, copy or distribute any portion of the Web Site. 
                    Graphical images and artworks are either originally created by Cloutcs Inc or obtained from 
                    svgrepo.com, undraw.co, drawkit.io or other websites with changes incorporated by Cloutics Inc. 
                    The licenses for Graphical images and artworks are applicable as released by the original creators.
                </li>
                <li>and other trademarks and/or service marks (including logos and designs) found on the Website are 
                    trademarks/service marks that identify and the goods and/or services provided by Cloutics Inc 
                    or the respective owners. Such marks may not be used under any circumstances without the prior 
                    written authorization of Cloutics Inc or the respective owners.</li>
            </p>
            <p style={{fontSize:'90%', fontWeight:400, margin:'.5em 0 '}}>
                Links to Third-Party Web Site
            </p>
            <p style={{ fontSize:'70%', margin:'.5em auto 1em', textAlign:'justify'}}>
                Cloutics.com/Cloutics.net may provide hyperlinks to third-party web sites as a convenience to users 
                of the Website. Cloutics.com/Cloutics.net does not control third-party web sites and is not 
                responsible for the contents of any linked-to, third-party web sites or any hyperlink in a linked-to 
                web site.  Cloutics.com/Cloutics.net does not endorse, recommend or approve any third-party web site 
                hyperlinked from the Website.  Cloutics.com/Cloutics.net will have no liability to any entity 
                for the content or use of the content available through such hyperlink.
            </p>
            <p style={{fontSize:'90%', fontWeight:400, margin:'.5em 0 '}}>
                No Representations or Warranties; Limitations on Liability
            </p>
            <p style={{ fontSize:'70%', margin:'.5em auto 1em', textAlign:'justify'}}>
                The information and materials on the Website could include technical inaccuracies or typographical errors. 
                Changes are periodically made to the information contained herein. Cloutics.com/Cloutics.net 
                MAKES NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO ANY INFORMATION, MATERIALS OR GRAPHICS ON THE WEBSITE, 
                ALL OF WHICH IS PROVIDED ON A STRICTLY "AS IS" BASIS, WITHOUT WARRANTY OF ANY KIND AND HEREBY EXPRESSLY 
                DISCLAIMS ALL WARRANTIES WITH REGARD TO ANY INFORMATION, MATERIALS OR GRAPHICS ON THE WEBSITE, INCLUDING 
                ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. 
                UNDER NO CIRCUMSTANCES SHALL THE SITE OWNER OR PUBLISHER BE LIABLE UNDER ANY THEORY OF RECOVERY, 
                AT LAW OR IN EQUITY, FOR ANY DAMAGES, INCLUDING WITHOUT LIMITATION, SPECIAL, DIRECT, INCIDENTAL, 
                CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING, BUT NOT LIMITED TO LOSS OF USE OR LOST PROFITS), 
                ARISING OUT OF OR IN ANY MANNER CONNECTED WITH THE USE OF INFORMATION OR SERVICES, OR THE FAILURE 
                TO PROVIDE INFORMATION OR SERVICES, FROM THE WEBSITE.
            </p>
            <p style={{fontSize:'90%', fontWeight:400, margin:'.5em 0 '}}>
                Changes to These Terms of Use
            </p>
            <p style={{ fontSize:'70%', margin:'.5em auto 1em', textAlign:'justify'}}>
                Cloutics.com/Cloutics.net reserves the right to change these Terms of Use at any time by posting new 
                Terms of Use at this location. You can send messages to us using the "Reach Us" option on the header of 
                this website with any questions relating to these Terms of Use or by U.S. mail to the 
                US (NJ) address mentioned in the "About Us" page.
            </p>

      
        
                
        
        </div>
      </div>
    
  </Layout>
);

export default TransformPage;
